.herotext {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    padding-bottom: 80px;

    @include media('<1450px') {
        grid-gap: 20px 50px;
    }

    @include media('<1250px') {
        grid-gap: 40px;
        padding-bottom: 60px;
    }

    @include media('<850px') {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
    }

    @include media('<768px') {
        padding-bottom: 40px;
    }

    h2 {
        
        @include media('<1250px') {
            grid-column-start: 1;
            grid-column-end: 3;
        }

        @include media('<850px') {
            grid-column-start: auto;
            grid-column-end: auto;
        }
    }

    a {
        height: max-content;
        width: fit-content;
    }
}