.heroblog {
    position: relative;
    height: auto;
    padding-top: 280px;
    padding-bottom: 200px;

    @include media('<1250px') {
        padding-top: 220px;
    }

    @include media('<768px') {
        padding-top: 120px;
        padding-bottom: 80px;
    }

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(18, 27, 62, 0.60);
        position: absolute;
        left: 0;
        top: 0;
    }

    img {
        z-index: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-fit: cover;
    }

    &-con {
        z-index: 2;
        position: relative;
        width: 100%;

        @include media('<1480px') {
            width: calc( 100% - 100px );
        }

        @include media('<768px') {
            width: 100%;
        }

        h1 {
            color: #fff;
            width: 100%;
            max-width: 850px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;

            @include media('<1250px') {
                max-width: 700px;
            }

            @include media('<=1024px') {
                max-width: 600px;
            }

            @include media('<768px') {
                max-width: 400px;
            }
        }
    }
}