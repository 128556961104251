.blog {
    position: relative;

    &-main {
        position: relative;
        padding-top: 100px;
        padding-bottom: 150px;

        @include media('<1450px') {
            padding-top: 80px;
            padding-bottom: 130px;
        }

        @include media('<1250px') {
            padding-top: 60px;
            padding-bottom: 100px;
        }

        @include media('<=1024px') {
            padding-top: 40px;
            padding-bottom: 80px;
        }

        @include media('<768px') {
            padding-top: 30px;
            padding-bottom: 50px;
        }

        &-header {
            position: relative;
            width: 100%;
            max-width: 850px;
            margin: 0 auto 80px auto;
            
            @include media('<1250px') {
                margin: 0 auto 60px auto;
            }
    
            @include media('<=1024px') {
                margin: 0 0 40px 0;
                max-width: none;
            }
    
            @include media('<768px') {
                margin: 0 auto 30px auto;
            }

            &-info {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 60px;

                @include media('<=1024px') {
                    margin-bottom: 40px;
                }

                @include media('<768px') {
                    flex-direction: column;
                    margin-bottom: 30px;
                }

                &-i {
                    display: flex;
                    align-items: center;

                    @include media('<768px') {
                        width: 100%;
                    }

                    svg {
                        width: 35px;
                        height: 35px;
                        min-width: 35px;
                        min-height: 35px;
                        max-width: 35px;
                        max-height: 35px;
                        margin-right: 17px;
                    }

                    span {
                        font-size: 20px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: left;
                        color: $dark-blue-grey;

                        @include media('<=1024px') {
                            font-size: 18px;
                        }

                        @include media('<768px') {
                            font-size: 16px;
                        }
                    }

                    &:not(:last-child) {
                        margin-right: 40px;

                        @include media('<768px') {
                            margin-right: 0;
                            margin-bottom: 15px;
                        }
                    }
                }
            }

            p {
                font-size: 22px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.68;
                letter-spacing: normal;
                text-align: left;
                color: $dark-blue-grey;

                @include media('<1250px') {
                    font-size: 20px;
                }

                @include media('<=1024px') {
                    font-size: 18px;
                }

                @include media('<768px') {
                    font-size: 16px;
                }
            }
        }

        &-backlink {
            max-width: 850px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 100px;

            @include media('<1450px') {
                margin-top: 80px;
            }
    
            @include media('<1250px') {
                margin-top: 60px;
            }
    
            @include media('<=1024px') {
                margin-top: 40px;
                max-width: none;
            }
    
            @include media('<768px') {
                margin-top: 30px;
            }

            a {

                svg {
                    margin-left: 0;
                    margin-right: 25px;
                    transform: rotate(0);
                }

                &:hover {

                    svg {
                        transform: rotate(0) translateX(-10px);
                    }
                }
            }
        }
    }
}