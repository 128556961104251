$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 5px;
$hamburger-layer-color: #121b3e;
$hamburger-hover-opacity: 1;

@import '~photoswipe/dist/photoswipe';
@import '~photoswipe/dist/default-skin/default-skin';
@import '~hamburgers/_sass/hamburgers/hamburgers';
@import '~include-media/dist/include-media';
@import '~swiper/swiper.scss';

@import './components/usable';

@import './components/general';
@import './components/header';
@import './components/footer';
@import './components/hero';
@import './components/articledesign';
@import './components/evtdesign';
@import './components/herotxt';
@import './components/heropage';
@import './components/heroevt';
@import './components/heroblog';
@import './components/content';

@import './pages/homepage';
@import './pages/intrebaripropuneri';
@import './pages/about';
@import './pages/events';
@import './pages/event';
@import './pages/blogs';
@import './pages/blog';