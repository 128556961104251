.hero {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 68px;

    @include media('<1450px') {
        grid-gap: 20px 50px;
    }

    @include media('<1250px') {
        grid-gap: 40px;
    }

    @include media('<850px') {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
    }

    &-title {
        position: relative;
    }

    &-description {

        p {
            margin-bottom: 25px;

            @include media('<768px') {
                margin-bottom: 20px;
            }
        }
    }

    a {
        width: fit-content;
    }
}