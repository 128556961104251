.footer {
    position: relative;
    background-color: $dark-blue-grey;

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media('<768px') {
            padding-top: 30px;
            padding-bottom: 35px;
            flex-direction: column;
        }
    }

    &-content {
        display: flex;
        align-items: center;

        @include media('<768px') {
            flex-direction: column;
            justify-content: center;
        }

        &-logo {
            position: relative;
            width: 150px;
            margin-right: 25px;

            @include media('<=1024px') {
                width: 120px;
            }

            @include media('<768px') {
                margin-bottom: 30px;
            }

            img,
            svg {
                width: 100%;
            }

            svg {

                path {

                    &:not(:nth-child(2)) {
                        fill: #fff;
                    }
                }
            }
        }

        &-about {
            position: relative;
            display: flex;

            @include media('<768px') {
                flex-direction: column-reverse;
                align-items: center;
                margin-bottom: 20px;
            }

            &-item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @include media('<768px') {
                    align-items: center;
                }

                &:not(:last-of-type) {
                    border-right: 2px solid $brown;
                    padding-right: 30px;

                    @include media('<=1024px') {
                        padding-right: 20px;
                    }

                    @include media('<768px') {
                        margin-top: 20px;
                    }
                }

                &:not(:first-of-type) {
                    padding-left: 30px;

                    @include media('<=1024px') {
                        padding-left: 20px;
                    }
                }

                @include media('<768px') {
                    padding-right: 0!important;
                    padding-left: 0!important;
                    border: none!important;
                }

                span {
                    margin-bottom: 5px;
                }

                span,
                a,
                footer {
                    font-size: 12px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.67;
                    letter-spacing: normal;
                    text-align: left;
                    color: #fff;

                    @include media('<768px') {
                        text-align: center;
                    }
                }
                
                a {

                    &:not(:last-of-type) {
                        margin-bottom: 5px;
                    }

                    &:hover {
                        color: $brown;
                    }
                }
            }
        }
    }

    &-links {
        position: relative;
        display: flex;

        svg {
            width: 25px;
            height: 25px;

            * {
                fill: #fff;
            }
        }

        a {

            &:not(:last-of-type) {
                margin-right: 25px;
            }

            &:hover {

                svg {
                    transform: scale(1.2,1.2);

                    * {
                        fill: $brown;
                    }
                }
            }
        }
    }
}