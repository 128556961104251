.articledesign {
    position: relative;
    box-shadow: 0 -20px 86px 0 rgba(73, 81, 111, 0.38);
    background-color: #fff;
    transition: .34s $easing;
    cursor: pointer;

    &-img {
        width: 100%;
        height: 200px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            transition: .8s $easing;
        }
    }

    &-con {
        position: relative;
        height: auto;
        padding: 28px 25px 48px 25px;
        transition: .34s $easing;
        display: flex;
        flex-direction: column;

        * {
            transition: .34s $easing;
        }

        h3 {
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3;
            letter-spacing: normal;
            text-align: left;
            color: $dark-blue-grey;
            margin-bottom: 15px;
        }

        p {
            position: relative;
            font-size: 14px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.55;
            letter-spacing: normal;
            text-align: left;
            color: $dark-blue-grey;
            margin-bottom: 28px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        a {
            font-size: 14px;

            svg {
                width: 14px;
                height: 14px;
            }
        }

        .link {
            margin-top: auto;
        }
    }

    &:hover {
        box-shadow: 0 -20px 86px 40px rgba(73, 81, 111, 0.38);

        @include media('<768px') {
            box-shadow: 0 -20px 86px 0 rgba(73, 81, 111, 0.38);
        }

        img {
            transform: scale(1.05,1.05);

            @include media('<768px') {
                transform: none;
            }
        }

        h3,
        p,
        a {
            color: $brown;

            @include media('<768px') {
                color: inherit;
            }
        }

        svg {

            * {
                stroke: $brown;

                @include media('<768px') {
                    stroke: inherit;
                }
            }
        }
    }
}