.isp {

    &-form {
        display: flex;
        flex-direction: row;
        margin-top: 120px;

        @include media('<1000px') {
            flex-direction: column;
        }

        @include media('<=460px') {
            margin-top: 0;
        }

        &-img {
            background-image: url('../../img/gallery/marius.jpeg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 50%;

            @include media('<1000px') {
                height: 300px;
                width: 100%;
            }

            @include media('<=460px') {
                display: none;
            }
        }

        &-background {
            background-color: #eaebef;
            margin-bottom: 40.5px;
            width: 50%;
            
            @include media('<1000px') {
                width: 94%;
            }

            @include media('<=768px') {
                width: 100%;
            }
        }

        &-main {
            padding: 45px;

            @include media('<=768px') {
                padding: 30px;
            }

            .radio-buttons {
                display: flex;
                flex-direction: row;
    
                .category {
                    position: relative;
                    color: #121b3e;
                    font-family: $font;
                    font-size: 22px;
                    font-weight: 500;
                    cursor: pointer;

                    @include media('<=768px') {
                        font-size: 19px;
                    }

                    label {
                        cursor: pointer;
                    }
    
                    input[type="radio"] {
                        position: absolute;
                        -webkit-appearance: auto;
                        opacity: 0;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                    }

                    input[type="radio"]:checked+label {
                        font-weight: bold;
                    }
                }
    
                .margin-left {
                    margin-left: 45px;

                    @include media('<=768px') {
                        margin-left: 30px;
                    }
                }
    
            }

            .isp-input {

                margin: 30px auto;;

                textarea {
                    background-color: rgba(18, 27, 62, 0.1);
                    font-family: $font;
                    font-size: 18px;
                    font-weight: normal;
                    height: 190px;
                    border: none;
                    outline: none;
                    padding: 26px 20px;
                    resize: none;

                    @include media('<800px') {
                        font-size: 16px;
                        height: 130px;
                        padding: 20px 15px;
                    }

                    ::placeholder {
                        color: rgba(18, 27, 62, 0.47);
                        font-weight: 100;
                    }
                }

            }

            .small-note {
                color: rgba(18, 27, 62, 0.69);
                font-family: $font;
                font-size: 14px;
                font-weight: 300;

                @include media('<=768px') {
                    font-size: 12px;
                }
            }

            .isp-button {

                margin-top: 30px;

                button {
                    background-color: #b19e6c;
                    color: white;
                    font-family: $font;
                    font-size: 14px;
                    font-weight: bold;
                    padding: 10px 75px;

                    @include media('<=768px') {
                        font-size: 13px;
                        padding: 8px 50px;
                    }
                }

            }

        }

    }

    &-form.wrapper {
        @include media('<=460px') {
            padding: 0;
        }
    }

    &-intermediate-section {
        color: $dark-blue-grey;
        display: flex;
        flex-direction: row;
        margin-top: 120px;

        @include media('<1200px') {
            flex-direction: column;
            margin-top: 60px;
        }

        @include media('<=1024px') {
            margin-top: 35px;
        }

        @include media('<=768px') {
            margin-top: 25px;
        }

        .title {
            width: 50%;
            font-family: $font;
            font-size: 60px;
            font-weight: bold;
            line-height: 1.5;

            @include media('<1440px') {
                font-size: 50px;
                width: 95%;
            }

            @include media('<1200px') {
                font-size: 40px;
                width: 95%;
            }

            @include media('<=768px') {
                font-size: 32px;
                width: 100%;
            }

            @include media('<=460px') {
                font-size:30px;
            }
        }

        .description {
            font-family: $font;
            font-size: 24px;
            font-weight: 300;
            line-height: 1.67;
            margin-left: 5%;
            width: 45%;
            
            @include media('<1200px') {
                font-size: 20px;
                margin-left: 0;
                margin-top: 40px;
                width: 100%;
            }

            @include media('<=768px') {
                font-size: 17px;
                margin-left: 0;
                margin-top: 10px;
            }

            @include media('<=460px') {
                font-size: 15px;
            }
        }
    }

    &-items {
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
        grid-template-rows: auto;
        justify-content: space-between;
        margin-bottom: 90px;
        margin-top: 120px;

        @include media('<1200px') {
            grid-template-columns: 50% 50%;
        }

        @include media('<=768px') {
            margin-bottom: 50px;
            margin-top: 70px;
        }

        @include media('<=460px') {
            grid-template-columns: 100%;
        }

        .item {
            margin-bottom: 100px;
            width: 330px;

            @include media('<1200px') {
                margin: 0 auto 85px auto;
            }

            @include media('<800px') {
                width: 250px;
            }

            @include media('<620px') {
                width: 185px;
            }

            @include media('<=460px') {
                width: 85%;
            }

            .category {
                color: $white;
                font-family: $font;
                font-size: 10px;
                font-weight: bold;
                margin-left: auto;
                text-align: center;
                text-transform: uppercase;
                padding: 3px 14px;
                width: fit-content;
            }

            .big-card {
                box-shadow: 0 23px 86px 0 rgba(0, 0, 0, 0.37);
                color: $white;
                font-family: $font;
                font-size: 16px;
                font-weight: bold;
                line-height: 1.56;
                padding: 29px 30px 28px 31px;

                @include media('<1200px') {
                    box-shadow: 0 13px 66px 0 rgba(0, 0, 0, 0.33);
                }

                @include media('<800px') {
                    font-size: 14px;
                    padding: 19px 23px 24px 23px;
                }

                @include media('<620px') {
                    font-size: 13px;
                    padding: 17px 21px 22px 21px;
                }

                @include media('<=460px') {
                    padding: 26px 27px 25px 28px;
                }

                .date {
                    color: rgba(255, 255, 255, 0.75);
                    font-size: 10px;
                    font-weight: normal;
                    line-height: normal;
                    transform: translateY(10px);
                }
            }

            .question {
                .big-card {
                    background-color: $brown;
                }

                .category {
                    background-color: $dark-blue-grey;
                }
            }
    
            .proposal {
                .big-card {
                    background-color: $dark-blue-grey;
                }

                .category {
                    background-color: $brown;
                }
            }

            @include media('>=1199px') {

                &-middle {
                    margin: auto;
                    transform: translateY(65px);
                }
    
                &-right {
                    margin-left: auto;
                }

            }
        }
    }

    .margin-bottom {
        margin-bottom: 75px;

        @include media('<=768px') {
            margin-bottom: 50px;
        }
    }

}