.heropage {
    position: relative;
    z-index: 2;

    &:before {
        content: "";
        width: 100%;
        height: calc( 100% - 100px );
        background-color: $pale-lilac;
        position: absolute;
        left: 0;
        bottom: 100px;
        z-index: -1;

        @include media('<=1024px') {
            display: none;
            content: none;
        }
    }

    .wrapper  {
        position: relative;
        padding: 0;

        &.right {

            img {
                margin-left: 30px;

                @include media('<1480px') {
                    margin-left: 0;
                }
            }

            .heropage-con {
                right: 30px;
                left: auto;

                @include media('<1480px') {
                    right: 0;
                }

                &:before {
                    left: -100px;
                    bottom: -100px;
                }
            }

        }

        &.left {

            img {
                margin-left: auto;
                margin-right: 30px;

                @include media('<1480px') {
                    margin-right: 0;
                }
            }

            .heropage-con {
                right: auto;
                left: 30px;

                @include media('<1480px') {
                    left: 0;
                }

                &:before {
                    right: -100px;
                    left: auto;
                    bottom: -100px;
                }
            }

        }
    }

    img {
        position: relative;
        width: 75%;
        height: 600px;
        margin: 0;
        object-fit: cover;
        z-index: 1;

        @include media('<1200px') {
            width: 90%;
        }

        @include media('<=1024px') {
            width: 100%;
            height: 380px;
        }

        @include media('<768px') {
            height: 280px;
        }

        @include media('<450px') {
            height: 220px;
        }
    }

    &-con {
        position: absolute;
        left: 30px;
        z-index: 2;
        bottom: 100px;
        margin: auto;
        height: auto;
        width: 60%;
        background-color: $brown;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 110px 90px;

        @include media('<1200px') {
            padding: 80px 30px;
        }

        @include media('<=1024px') {
            width: 100%;
            position: relative;
            bottom: 0;
            padding: 40px 30px;
        }

        @include media('<768px') {
            padding: 30px 20px;
        }

        h1 {
            color: #fff;
            margin-bottom: 25px;

            @include media('<768px') {
                margin-bottom: 15px;
            }
        }

        p {
            color: #fff;
            text-align: center;
            font-weight: 500;
        }

    }

}
