.header {
    position: relative;

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media('<=1024px') {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        @include media('<768px') {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    &-logo {
        position: relative;
        width: 200px;
        height: 63.5px;

        @include media('<1250px') {
            width: 150px;
        }

        @include media('<=1024px') {
            z-index: 16;
            height: auto;
        }

        @include media('<768px') {
            width: 120px;
        }

        svg,
        img {
            width: 100%;
            height: 100%;
        }

        svg {

            path {

                &:not(:nth-child(2)) {
                    fill: $dark-blue-grey;
                }
            }
        }
    }

    &-nav {
        position: relative;
        margin-right: auto;
        margin-left: 60px;

        @include media('<=1024px') {
            visibility: hidden;
            margin-left: 0;
            pointer-events: none;
            width: 100%;
            overflow: scroll;
            -webkit-overflow-scrolling: touch;
            position: fixed;
            top: 0;
            left: 0;
            will-change: transform;
            transition: 0.4s cubic-bezier(0.215, 0.610, 0.355, 1.000);
            display: block;
            transform: translateY(-100%);
            padding: 0px 20px 150px 20px;
            background-color: #fff;
            z-index: 15;
            min-height: 100vh;
            min-height: -webkit-fill-available;
            height: 100vh;

            &.-open {
                visibility: visible;
                pointer-events: auto;
                transform: translateX(0);

                svg {

                    * {
                        fill: $brown!important;
                    }
                }
            }

            .header-links {
                display: flex;
                margin-top: 50px;
            }
            
        }

        nav {

            @include media('<=1024px') {
                height: 100%;
            }
        }

        ul {
            display: flex;
            align-items: center;

            @include media('<=1024px') {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
            }
        }

        li {
            position: relative;
            z-index: 1;

            &:not(:last-of-type) {
                margin-right: 30px;

                @include media('<1250px') {
                    margin-right: 20px;
                }

                @include media('<=1024px') {
                    margin-right: 0;
                    margin-bottom: 15px;
                }
            }
        }

        a {
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $dark-blue-grey;
            z-index: 2;

            @include media('<1250px') {
                font-size: 17px;
            }

            @include media('<=1024px') {
                font-size: 20px;
            }

            &:before {
                content: "";
                width: 0%;
                height: 10px;
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                bottom: 0;
                background-color: $brown;
                transition: .45s $easing;
                z-index: -1;
            }

            &:hover {

                &:before {
                    width: 100%;
                }
            }

            &.active {
                pointer-events: none;

                &:before {
                    width: 100%;
                }
            }
        }
    }

    &-links {
        position: relative;
        display: flex;

        @include media('<=1024px') {
            display: none;
        }

        svg {
            width: 35px;
            height: 35px;

            @include media('<1250px') {
                width: 30px;
                height: 30px;
            }
        }

        a {

            &:not(:last-of-type) {
                margin-right: 25px;

                @include media('<1250px') {
                    margin-right: 20px;
                }
            }

            &:hover {

                svg {
                    transform: scale(1.2,1.2);
                }
            }
        }
    }

    &-hamburger {
        display: none;

        @include media('<=1024px') {
            display: flex;
            z-index: 9999999;
        }
    }
}