.aboutpage {

    &-main {
        position: relative;
        margin-top: 120px;

        @include media('<1450px') {
            margin-top: 80px;
        }

        @include media('<=1024px') {
            margin-top: 60px;
        }

        @include media('<768px') {
            margin-top: 40px;
        }

        &-header {
            position: relative;
            display: flex;
            flex-direction: column;

            h3 {
                color: $dark-blue-grey;
            }

            h1 {
                line-height: 1.1;
                color: $dark-blue-grey;
                z-index: 1;
            }

            img {
                width: 100%;
                height: auto;
                margin-top: -95px;

                @include media('<1450px') {
                    margin-top: -30px;
                }
            }
        }

        &-about {
            position: relative;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 100px;
            padding-top: 100px;
            padding-bottom: 100px;

            @include media('<=1024px') {
                grid-gap: 60px;
                padding-top: 60px;
                padding-bottom: 60px;
            }

            @include media('<768px') {
                grid-gap: 20px;
                padding-top: 40px;
                padding-bottom: 4 0px;
                grid-template-columns: repeat(1, 1fr);
            }

            p {
                font-size: 24px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: normal;
                text-align: left;
                color: $dark-blue-grey;

                @include media('<1450px') {
                    font-size: 22px;
                }

                @include media('<=1024px') {
                    font-size: 20px;
                }

                @include media('<768px') {
                    font-size: 18px;
                }
            }
        }

        &-slider {
            overflow: hidden;

            .swiper-container {
                overflow: visible;
            }

            .swiper-slide {
                height: auto;

                img {
                    width: 100%;
                    height: 500px;
                    object-position: center;
                    object-fit: cover;

                    @include media('<768px') {
                        height: 360px;
                    }

                    @include media('<450px') {
                        height: 300px;
                    }

                    @include media('<360px') {
                        height: 260px;
                    }
                }
            }
        }
    }

    &-formular {
        margin: 80px auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        @include media('<=1024px') {
            margin: 60px auto; 
        }

        @include media('<768px') {
            margin: 40px auto; 
            padding: 0 20px;

            iframe {
                width: 100%;
            }
        }
    }
}