.events {
    position: relative;

    &-items {
        position: relative;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 60px 80px;
        padding-bottom: 200px;

        @include media('<1350px') {
            grid-gap: 50px 30px;
        }

        @include media('<=1024px') {
            grid-gap: 20px;
            margin-top: 0;
            padding-bottom: 100px;
        }

        @include media('<768px') {
            padding-bottom: 60px;
        }

        @include media('<600px') {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}