.event {
    position: relative;

    .heroevt-con {
        padding-bottom: 80px;

        @include media('<768px') {
            padding-bottom: 40px;
        }
    }

    &-main {
        position: relative;
        padding-top: 120px;
        padding-bottom: 150px;

        @include media('<1450px') {
            padding-bottom: 130px;
        }

        @include media('<1250px') {
            padding-top: 100px;
            padding-bottom: 100px;
        }

        @include media('<=1024px') {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        @include media('<768px') {
            padding-top: 60px;
            padding-bottom: 50px;
        }

        &-backlink {
            max-width: 850px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 100px;

            @include media('<1450px') {
                margin-top: 80px;
            }
    
            @include media('<1250px') {
                margin-top: 60px;
            }
    
            @include media('<=1024px') {
                margin-top: 40px;
                max-width: none;
            }
    
            @include media('<768px') {
                margin-top: 30px;
            }

            a {

                svg {
                    margin-left: 0;
                    margin-right: 25px;
                    transform: rotate(0);
                }

                &:hover {

                    svg {
                        transform: rotate(0) translateX(-10px);
                    }
                }
            }
        }
    }
}