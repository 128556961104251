// Single blog & Single Event
.content {
    position: relative;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol,
    .categorynamehash {
        width: 100%;
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;

        @include media('<=1024px') {
            max-width: none;
        }
    }

    .categorynamehash {
        display: block;
        margin-top: 20px;
        color: $dark-blue-grey;
    }

    img {
        width: 100%;
        height: auto;
        margin: 80px 0;
        max-height: 800px;
        object-fit: cover;
        object-position: center;

        @include media('<=1024px') {
            margin: 60px 0;
        }

        @include media('<768px') {
            margin: 40px 0;
        }
    }

    h1,
    h2 {
        font-size: 34px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: normal;
        text-align: left;
        color: $dark-blue-grey;
        margin-bottom: 35px;

        &:not(:first-of-type) {
            margin-top: 60px;

            @include media('<=1024px') {
                margin-top: 50px;
            }
    
            @include media('<768px') {
                margin-top: 40px;
            }
        }

        @include media('<1450px') {
            font-size: 26px;
        }

        @include media('<1250px') {
            font-size: 24px;
        }

        @include media('<=1024px') {
            font-size: 22px;
            margin-bottom: 25px;
        }

        @include media('<768px') {
            font-size: 20px;
            margin-bottom: 15px;
        }
    }

    h3,
    h4,
    h5,
    h6 {   
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: normal;
        text-align: left;
        color: $dark-blue-grey;
        margin-top: 30px;
        margin-bottom: 15px;

        @include media('<1450px') {
            font-size: 22px;
        }

        @include media('<1250px') {
            font-size: 20px;
        }

        @include media('<=1024px') {
            font-size: 18px;
        }

        @include media('<768px') {
            font-size: 16px;
            margin-top: 25px;
        }
    }

    ol {
        list-style-position: inside;
    }

    ul,
    ol {
        position: relative;
        margin-top: 25px;
        margin-bottom: 40px;
    }

    ul {

        li {
            padding-left: 25px;
            
            &:before {
                content: "";
                width: 6px;
                min-width: 6px;
                max-width: 6px;
                height: 6px;
                min-height: 6px;
                max-height: 6px;
                border-radius: 50%;
                background-color: $brown;
                position: absolute;
                left: 0;
                top: 15px;

                @include media('<1450px') {
                    top: 12px;
                }
        
                @include media('<1250px') {
                    top: 10px;
                }
        
                @include media('<=1024px') {
                    width: 5px;
                    min-width: 5px;
                    max-width: 5px;
                    height: 5px;
                    min-height: 5px;
                    max-height: 5px;
                }
        
                @include media('<768px') {
                    top: 9px;
                }
            }
        }
    }

    li {
        position: relative;
        z-index: 1;
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: $dark-blue-grey;
    
        &:not(:last-child) {
            margin-bottom: 25px;
        }

        @include media('<1450px') {
            font-size: 19px;
        }

        @include media('<1250px') {
            font-size: 17px;
        }

        @include media('<=1024px') {
            font-size: 16px;
        }

        @include media('<768px') {
            font-size: 14px;
        }

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    p {
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: $dark-blue-grey;
    
        &:not(:last-child) {
            margin-bottom: 25px;

            @include media('<768px') {
                margin-bottom: 20px;
            }
        }

        @include media('<1450px') {
            font-size: 19px;
        }

        @include media('<1250px') {
            font-size: 17px;
        }

        @include media('<=1024px') {
            font-size: 16px;
        }

        @include media('<768px') {
            font-size: 14px;
        }
    }

    .pswp__item {

        img {
            object-fit: cover;
        }
    }

    .gallery {
        width: 100%;
        max-width: 850px;
        height: auto;
        margin: 0 auto;

        @include media('<=1024px') {
            max-width: none;
        }

        @include media('<768px') {
            margin: 40px 0;
        }

        &-content {
            display: grid;
            grid-template-columns: repeat(3,1fr);
            grid-gap: 35px;
            margin: 80px auto;

            @include media('<=1024px') {
                margin: 60px 0;
                grid-gap: 20px;
            }

            @include media('<768px') {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 15px;
                margin: 40px 0;
            }

            @include media('<350px') {
                grid-template-columns: repeat(1, 1fr);
                margin: 40px 0;
            }

            &:empty {
                display: none;
            }

            img {
                margin: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                max-height: 220px;

                @include media('<=1024px') {
                    height: 200px;
                    max-height: 200px;
                }

                @include media('<768px') {
                    height: 180px;
                    max-height: 180px;
                }

                @include media('<350px') {
                    max-height: none;
                    height: auto;
                }
            }

            figure {
                position: relative;
                transition: .34s $easing;
                cursor: pointer;

                &:before {
                    content: "";
                    width: 80%;
                    height: 80%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    margin: auto;
                    background: rgba( $brown, .8 );
                    transition: .34s $easing;
                    z-index: 1;
                    opacity: 0;
                    pointer-events: none;
                }

                &:hover {

                    &:before {
                        width: 100%;
                        height: 100%;
                        opacity: 1;
                    }
                }
            }
        }
    }
}