.eventdesign {
    position: relative;
    height: 680px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 50px;

    @include media('<1150px') {
        height: 500px;
    }

    @include media('<=1024px') {
        height: auto;
        padding: 200px 30px 30px 30px;
    }

    @include media('<768px') {
        padding: 140px 30px 30px 30px;
    }

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba( $dark-blue-grey, .70 );
        z-index: 0;
    }

    .label-date {
        position: absolute;
        right: 40px;
        top: 0;
        background-color: #fff;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: $brown;
        width: auto;
        padding: 12px;
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-position: center;
        object-fit: cover;
        z-index: -1;
    }

    h3 {
        color: #fff;
        z-index: 1;
        margin-bottom: 30px;

        @include media('<768px') {
            margin-bottom: 20px;
        }
    }

    p {
        color: #fff;
        z-index: 1;
        margin-bottom: 30px;
        width: 100%;
        max-width: 400px;

        @include media('<768px') {
            margin-bottom: 20px;
            max-width: none;
        }
    }

    a {
        z-index: 1;
        color: #fff;
        
        &:before {
            background-color: #fff;
        }
    }

}