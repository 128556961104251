.blogs {
    position: relative;

    &-header {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: $pale-lilac;
        padding-top: 30px;
        padding-bottom: 100px;

        @include media('<=1024px') {
            align-items: flex-start;
            justify-content: flex-start;
            padding: 20px 30px 80px 30px;
        }

        @include media('<768px') {
            padding: 20px 20px 30px 20px;
        }

        h1 {
            color: $dark-blue-grey;
            width: 100%;
            max-width: 750px;
            margin-bottom: 30px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;

            @include media('<=1024px') {
                margin: 0 0 20px 0;
                text-align: left;
            }
        }

        p {
            color: $dark-blue-grey;
            width: 100%;
            max-width: 750px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;

            @include media('<=1024px') {
                margin: 0;
                text-align: left;
            }
        }
    }

    .filtersbtns {
        padding-top: 0;
        transform: translateY(-27px);
        
        @include media('<768px') {
            transform: none;
            background: $pale-lilac;
        }
    }

    &-items {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 100px 60px;
        padding-bottom: 200px;

        @include media('<1200px') {
            grid-gap: 90px 30px;
        }

        @include media('<=1024px') {
            grid-gap: 60px 30px;
            padding-bottom: 100px;
            grid-template-columns: repeat(2, 1fr);
        }

        @include media('<768px') {
            grid-gap: 30px;
            grid-template-columns: repeat(1, 1fr);
            padding-bottom: 60px;
        }

        &-fi {
            position: relative;
            grid-column-start: 1;
            grid-column-end: 4;
            background: $pale-lilac;
            display: flex;
            align-items: stretch;

            @include media('<=1024px') {
                grid-column-end: 3;
            }

            @include media('<768px') {
                grid-column-end: auto;
            }

            @include media('<768px') {
                width: calc( 100% + 40px );
                transform: translateX(-20px);
                flex-direction: column;
            }

            &-img {
                width: 30%;

                @include media('<=1024px') {
                    width: 40%;
                }

                @include media('<768px') {
                    width: 100%;
                }

                img {
                    width: 100%;
                    height: 100%;
                    max-height: 500px;
                    object-fit: cover;
                }
            }

            &-con {
                position: relative;
                width: 70%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                padding: 70px;

                @include media('<=1024px') {
                    padding: 50px 30px;
                    width: 60%;
                }

                @include media('<768px') {
                    width: 100%;
                    padding: 35px 20px;
                }

                &-infos {
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;

                    @include media('<768px') {
                        margin-bottom: 20px;
                    }

                    span {
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: left;
                        color: $brown;

                        @include media('<768px') {
                            font-size: 14px;
                        }

                        &:not(:last-of-type) {
                            padding-right: 15px;
                            margin-right: 15px;
                            border-right: 1px solid $brown;

                            @include media('<768px') {
                                margin-right: 10px;
                                padding-right: 10px;
                            }
                        }
                    }
                }

                h2 {
                    color: $dark-blue-grey;
                    margin-bottom: 30px;
                    text-align: left;

                    @include media('<768px') {
                        margin-bottom: 20px;
                    }
                }

                p {
                    color: $dark-blue-grey;
                    margin-bottom: 40px;
                    line-height: 1.5;

                    @include media('<768px') {
                        margin-bottom: 20px;
                    }
                }

                a {
                    font-size: 18px;

                    @include media('<768px') {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}