* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: subpixel-antialiased;
    outline: none!important;
}

body {
    position: relative;
    width: 100%;
}

a:focus,
input:focus,
button:focus,
select:focus,
textarea:focus {
    outline: none;
}

a,
svg,
img,
path {
    transition: .34s $easing;
}

html, body {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

ul, ol {
    list-style-position: outside;
}

ul {
    list-style: none;
}

ol {
    list-style: decimal;
}

a {
    text-decoration: none;
}

a img {
    border: none;
}

img, video {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
}

video {
    outline: none;
}

header, footer, section, article, aside, main, nav, svg {
    display: block;
}

table {
    border-collapse: collapse;
}

input, textarea, select, button {
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
}

input[type='text'], input[type='email'], textarea, select {
    width: 100%;
}

input[type='submit'] {
    cursor: pointer;
}

select, button {
    cursor: pointer;
}

textarea {
    display: block;
    overflow: auto;
    resize: vertical;
}

button {
    background: none;
    border: none;
    cursor: pointer;
}
