body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*.blog-content img {*/
/*  width: 80% !important;*/
/*  display: flex !important;*/
/*  justify-content: center;*/
/*  margin: auto !important;*/
/*}*/