//font
$font: 'Montserrat', sans-serif;

//colors
$brown: #b19e6c;
$white: #fff;
$dark-blue-grey: #121b3e;
$dark-blue-grey-10: rgba(18, 27, 62, 0.1);
$dark-blue-grey-10: rgba(18, 27, 62, 0.1);
$black: #1d1d1d;
$white-80: rgba(255, 255, 255, 0.8);
$dark-blue-grey-70: rgba(18, 27, 62, 0.7);
$pale-lilac : #eaebef;

//vars

$easing: cubic-bezier(0.215, 0.610, 0.355, 1.000);

body {

    &.bghero {
        
        .header {
            background: $pale-lilac;
        }
    }

    &.float {
        
        .header {
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 9;

            .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
                background-color: #fff;
            }

            &-logo {

                svg {

                    path {

                        &:not(:nth-child(2)) {
                            fill: #fff;
                        }
                    }
                }
            }

            .hamburger {

                &.is-active {

                    .hamburger-inner {
                        background-color: $dark-blue-grey;

                        &::before,
                        &::after {
                            background-color: $dark-blue-grey;
                        }
                    }
                }
            }

            &-nav {
                
                a {
                    color: #fff;
                }

                &.-open {
                    
                    a {
                       color: $dark-blue-grey; 
                    }
                }
            }

            &-links {

                svg {

                    * {
                        fill: #fff;
                    }
                }
            }
        }
    }
}

// Usable items

.wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1480px; //wrapper is 1420px
    padding-left: 30px;
    padding-right: 30px;

    @include media('<768px') {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.btn {
    position: relative;
    background-color: $brown;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    padding: 14px 30px;
    z-index: 1;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        -webkit-clip-path: circle(0 at 50% 50%);
        clip-path: circle(0 at 50% 50%);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        background-color: $dark-blue-grey;
        transition: 0.6s;
        z-index: -1;
    }

    &:hover {

        &:before {
            -webkit-clip-path: circle(100% at 50% 50%);
            clip-path: circle(100% at 50% 50%);
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }
    }
}

.linkLine {
    position: relative;
    z-index: 1;

    &:before {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
        background-color: $brown;
        transition: .45s $easing;
        z-index: -1;
    }

    &:hover {

        &:before {
            width: 0%;
        }
    }
}

.title60 {
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: $dark-blue-grey;

    @include media('<1450px') {
        font-size: 50px;
    }

    @include media('<1250px') {
        font-size: 46px;
    }

    @include media('<=1024px') {
        font-size: 34px;
    }

    @include media('<768px') {
        font-size: 32px;
    }
}

.title50 {
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;

    @include media('<1450px') {
        font-size: 42px;
    }

    @include media('<1250px') {
        font-size: 32px;
    }

    @include media('<=1024px') {
        font-size: 28px;
    }

    @include media('<768px') {
        font-size: 26px;
    }
}

.title48 {
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: $dark-blue-grey;

    @include media('<1450px') {
        font-size: 40px;
    }

    @include media('<1250px') {
        font-size: 30px;
    }

    @include media('<=1024px') {
        font-size: 26px;
    }

    @include media('<768px') {
        font-size: 24px;
    }
}

.title40 {
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: $dark-blue-grey;

    @include media('<1450px') {
        font-size: 30px;
    }

    @include media('<1250px') {
        font-size: 26px;
    }

    @include media('<=1024px') {
        font-size: 24px;
    }

    @include media('<768px') {
        font-size: 22px;
    }
}

.title34 {
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: $dark-blue-grey;

    @include media('<1450px') {
        font-size: 26px;
    }

    @include media('<1250px') {
        font-size: 24px;
    }

    @include media('<=1024px') {
        font-size: 22px;
    }

    @include media('<768px') {
        font-size: 20px;
    }
}

.p20 {
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: $dark-blue-grey;

    @include media('<1450px') {
        font-size: 19px;
    }

    @include media('<=1024px') {
        font-size: 18px;
    }

    @include media('<768px') {
        font-size: 17px;
    }
}

.p18 {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: $dark-blue-grey;

    @include media('<1450px') {
        font-size: 17px;
    }

    @include media('<=1024px') {
        font-size: 16px;
    }

    @include media('<768px') {
        font-size: 14px;
    }
}

.p16 {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: $dark-blue-grey;

    @include media('<1450px') {
        font-size: 15px;
    }

    @include media('<=1024px') {
        font-size: 14px;
    }

    @include media('<768px') {
        font-size: 13px;
    }
}

.p14 {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: $dark-blue-grey;

    @include media('<=1024px') {
        font-size: 13px;
    }

    @include media('<768px') {
        font-size: 12px;
    }
}

.link {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: $dark-blue-grey;
    display: flex;
    align-items: center;

    @include media('<1450px') {
        font-size: 19px;
    }

    @include media('<=1024px') {
        font-size: 18px;
    }

    @include media('<768px') {
        font-size: 16px;
    }

    svg {
        margin-left: 15px;
        transform: rotate(180deg);
        width: 20px;
        height: 20px;
    }

    &:hover {
        color: $brown;

        svg {
            transform: translateX(5px) rotate(180deg);

            * {
                stroke: $brown;
            }
        }
    }
}

.simple-link {
    position: relative;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;

    &:before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: $dark-blue-grey;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -3px;
        margin: auto;
        transition: .34s $easing;
    }

    &:after {
        content: "";
        width: 0%;
        height: 1px;
        background-color: $brown;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -3px;
        margin: auto;
        transition: 1s $easing;
    }

    &:hover {

        &:before {
            width: 0;
        }

        &:after {
            width: 100%;
        }
    }
}

.filtersbtns {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
    padding-bottom: 80px;

    @include media('<=1024px') {
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;
        overflow: scroll;
        justify-content: flex-start;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */          

        &::-webkit-scrollbar {
            display: none;
        }
    }

    @include media('<768px') {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &-i {
        position: relative;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $dark-blue-grey;
        border-radius: 40px;
        border: 3px solid $brown;
        padding: 15px 24px;
        transition: .34s $easing;

        @include media('<=1024px') {
            min-width: fit-content;
        }

        @include media('<768px') {
            border-width: 2px;
            padding: 10px 18px;
        }

        &.active {
            background: $brown;
            color: #fff;
        }

        &:hover {
            background: $brown;
            color: #fff;
        }

        &:not(:last-of-type) {
            margin-right: 15px;
        }
    }
}