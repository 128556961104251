.heroevt {
    position: relative;
    height: auto;
    padding-top: 250px;

    @include media('<1250px') {
        padding-top: 150px;
    }

    @include media('<768px') {
        padding-top: 0;
    }

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(18, 27, 62, 0.60);
        position: absolute;
        left: 0;
        top: 0;
    }

    img {
        z-index: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-fit: cover;
    }

    &-con {
        z-index: 2;
        position: relative;
        width: 100%;
        background-color: $pale-lilac;
        transform: translateY(55px);
        margin-bottom: 55px;
        padding: 70px 30px;

        @include media('<1480px') {
            width: calc( 100% - 100px );
        }

        @include media('<768px') {
            width: 100%;
            margin-bottom: 0;
            transform: none;
            padding: 100px 20px 40px 20px;
            background: none;
        }

        h1 {
            color: $dark-blue-grey;
            margin-bottom: 30px;

            @include media('<768px') {
                color: #fff;
                margin-bottom: 20px;
            }
        }

        p {
            color: $dark-blue-grey;
            text-align: center;
            width: 100%;
            max-width: 780px;
            margin-left: auto;
            margin-right: auto;

            @include media('<768px') {
                color: #fff;
                margin-bottom: 20px;
            }
        }

        .date {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -38.5px;
            z-index: 1;
            margin: auto;
            width: fit-content;
            background: $brown;
            font-size: 30px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            padding: 20px 30px;

            @include media('<1480px') {
                bottom: -36px;
                font-size: 26px;
            }
    
            @include media('<=1024px') {
                font-size: 22px;
                bottom: -33.5px;
            }

            @include media('<768px') {
                font-size: 18px;
                bottom: -31px;
            }

            &:empty {
                display: none;
            }
        }
    }
}