.homepage {
    position: relative;

    &-header {
        position: relative;
        overflow-x: hidden;

        .wrapper {
            position: relative;
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;

            @include media('<=1024px') {
                padding-left: 0!important;
                padding-right: 0!important;
            }
        }

        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba( $dark-blue-grey, .78 );
            display: none;
            z-index: 2;
        }

        &-follow {
            position: absolute;
            display: flex;
            align-items: center;
            top: 0;
            bottom: 0;
            left: -250px;
            margin: auto;
            transform: rotate(90deg);

            @include media('<1650px') {
                left: -200px;
            }

            @include media('<1560px') {
                left: -180px;
            }

            @include media('<1500px') {
                display: none;
            }

            span {
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: $dark-blue-grey;
                margin-right: 15px;
            }

            a {
                color: $dark-blue-grey;

                &:not(:last-child) {
                    margin-right: 15px;
                }
            }
        }

        &-content {
            position: relative;
            width: 41%;
            background-color: $dark-blue-grey-10;
            padding: 100px 70px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            @include media('<1500px') {
                padding-top: 60px;
            }

            @include media('<1250px') {
                padding: 60px 45px;
            }

            @include media('<=1024px') {
                width: 100%;
                padding: 20px 30px 0 30px;
                z-index: 3;
                justify-content: center;
                align-items: center;
                background-color: transparent;
            }

            @include media('<768px') {
                padding-left: 20px;
                padding-right: 20px;
            }

            h1 {
                font-size: 73px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: $dark-blue-grey;
                margin-bottom: 80px;

                @include media('<1500px') {
                    font-size: 60px;
                    margin-bottom: 50px;
                }

                @include media('<1250px') {
                    font-size: 52px;
                    margin-bottom: 45px;
                }

                @include media('<=1024px') {
                    font-size: 48px;
                    max-width: 550px;
                    margin-bottom: 40px;
                    text-align: center;
                }

                @include media('<768px') {
                    font-size: 32px;
                    margin-bottom: 30px;
                    max-width: 300px;
                }
            }
        }

        &-about {
            position: relative;
            z-index: 3;
            width: 55%;
            height: 140px;
            background-color: $brown;
            margin-left: auto;
            padding: 30px 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            @include media('<1250px') {
                height: 100px;
            }

            @include media('<=1024px') {
                width: 100%!important;
                height: auto;
                margin-left: 0;
                justify-content: center;
            }

            @include media('<768px') {
                flex-direction: column;
                padding: 20px;
            }

            span {
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #fff;

                @include media('<1500px') {
                    font-size: 18px;
                }

                @include media('<1250px') {
                    font-size: 17px;
                }
                
                @include media('<=1024px') {
                    font-size: 16px;
                }

                @include media('<768px') {
                    text-align: center;
                    font-size: 14px;
                }

                &:not(:last-of-type) {
                    margin-right: 60px;

                    @include media('<1500px') {
                        margin-right: 30px;
                    }

                    @include media('<1250px') {
                        margin-right: 25px;
                    }

                    @include media('<768px') {
                        margin-bottom: 15px;
                        margin-right: 0;
                    }
                }
            }
        }

        &-img {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            z-index: 2;

            @include media('<=1024px') {
                width: 100%!important;
                z-index: 1;
                position: relative;
                margin-top: -23.5px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }

    }

    &-about {
        position: relative;
        padding-top: 150px;
        padding-bottom: 75px;
        overflow: hidden;
        
        @include media('<1450px') {
            padding-top: 100px;
            padding-bottom: 50px;
        }

        @include media('<=1024px') {
            padding-top: 60px;
            padding-bottom: 40px;
        }

        @include media('<850px') {
            padding-bottom: 0;
        }

        @include media('<768px') {
            padding-top: 40px;
            padding-bottom: 20px;
        }
        
        .swiper-container {
            overflow: visible;

            .swiper-slide {
                // opacity: .5;
                // filter: blur(1px);
                // transition: .34s $easing;

                // &.swiper-slide-visible {
                //     opacity: 1;
                //     filter: none;
                // }

                img {
                    width: 100%;
                    height: 500px;
                    object-position: center;
                    object-fit: cover;

                    @include media('<768px') {
                        height: 360px;
                    }

                    @include media('<450px') {
                        height: 300px;
                    }

                    @include media('<360px') {
                        height: 260px;
                    }
                }
            }
        }
    }

    &-isp {
        position: relative;
        padding-top: 75px;
        padding-bottom: 75px;
        display: flex;
        align-items: stretch;

        @include media('<1450px') {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        @include media('<=1024px') {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        @include media('<850px') {
            flex-direction: column-reverse;
            padding: 0;
        }

        @include media('<768px') {
            padding-top: 0;
        }
        
        &-img {
            position: relative;
            width: 50%;
            height: auto;
            max-height: 800px;

            @include media('<1250px') {
                max-height: inherit;
                height: auto;
            }

            @include media('<850px') {
                width: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
            }
        }

        &-main {
            width: 50%;
            height: auto;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: $dark-blue-grey-10;
            padding: 80px;

            @include media('<1250px') {
                padding: 60px 40px;
            }

            @include media('<850px') {
                padding: 40px 30px;
                width: 100%;
            }

            @include media('<768px') {
                padding: 40px 20px;
            }

            &-i {
                display: flex;
                flex-direction: column; 
                align-items: center;
                
                &:not(:last-child) {
                    margin-bottom: 80px;

                    @include media('<1250px') {
                        margin-bottom: 60px;
                    }

                    @include media('<850px') {
                        padding-bottom: 30px;
                        margin-bottom: 30px;
                        border-bottom: 1px solid rgba( $dark-blue-grey, .10 );
                    }

                }

                h2,
                p {
                    position: relative;
                    margin-bottom: 25px;
                    
                    @include media('<850px') {
                        margin-bottom: 20px;
                    }
                }

                .link {
                    font-size: 16px;

                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }

    &-blog {
        position: relative;
        padding-top: 75px;
        padding-bottom: 150px;
        overflow: hidden;

        @include media('<1450px') {
            padding-top: 50px;
            padding-bottom: 100px;
        }

        @include media('<=1024px') {
            padding-top: 40px;
            padding-bottom: 60px;
        }

        @include media('<768px') {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &-header {
            margin-bottom: 70px;

            @include media('<=1024px') {
                margin-bottom: 60px;
            }

            @include media('<768px') {
                margin-bottom: 40px;
            }
        }

        .swiper-container {
            overflow: visible;
        }

        .swiper-slide {
            height: auto;
        }
    }

    &-game {
        position: relative;
        
        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba( $dark-blue-grey, .78 );
        }

        .bg {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            height: 100%;
            object-fit: cover;
            object-position: center;
            z-index: -1;
        }

        .wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 55px;

            @include media('<=1024px') {
                grid-gap: 30px;
            }

            @include media('<=1024px') {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        &-con {
            position: relative;
            padding-top: 60px;
            padding-bottom: 120px;

            @include media('<=1024px') {
                padding-top: 30px;
                padding-bottom: 0;
            }

            h2 {
                color: #fff;
                margin-bottom: 40px;

                @include media('<=1024px') {
                    margin-bottom: 20px;
                }
            }

            p {
                color: $white-80;
            }
        }

        &-placeholder {
            background: #fff;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #fff;
            z-index: 5;
            transform: translateY(40px);

            @include media('<=1024px') {
                height: 250px;
                transform: translateY(10px);
            }

            iframe {
                width: 100%;
                height: 100%;
                min-width: 100%;
                min-height: 100%;
            }
        }
    }

    &-events {
        position: relative;
        padding-top: 190px;
        padding-bottom: 150px;
        background-color: $dark-blue-grey-10;

        @include media('<1450px') {
            padding-top: 120px;
            padding-bottom: 120px;
        }

        @include media('<1250px') {
            padding-top: 100px;
            padding-bottom: 100px;
        }

        @include media('<=1024px') {
            padding-top: 70px;
            padding-bottom: 80px;
        }

        @include media('<768px') {
            padding-top: 50px;
            padding-bottom: 60px;
        }

        &-items {
            position: relative;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 60px 80px;
            margin-top: 80px;

            @include media('<1350px') {
                grid-gap: 50px 30px;
            }

            @include media('<=1024px') {
                grid-gap: 20px;
                margin-top: 60px;
            }

            @include media('<768px') {
                margin-top: 40px;
            }

            @include media('<600px') {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}